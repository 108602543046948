
import { createSlice } from "@reduxjs/toolkit";

import { TypeBeer } from "./beerFunctions";

import { 
    initialState,
    addBeers
} from "./beerFunctions";

// Slice

export const beersSlice = createSlice({
    name: 'beers',
    initialState,
    reducers: {
        addArrayBeers: addBeers
    },
});

export const { addArrayBeers } = beersSlice.actions;

export type { TypeBeer };

export default beersSlice.reducer;
