
export const Theme = {
    color: {
        green: '#8e9a6b',
        darkGreen: '#161B07',
        softPink: '#d9c3d9',
        strongPink: '#DF97DF',
        lightGreen: '#c5ceb8',
        white: '#FFFFFF',
        black: '#000000',
        yellow: '#FDD559',
        mediumGreen: '#313B16',
        paleGreen: '#CAD4BD'
    },

    font: {
        title: "'Quinlliyk'",
        text: "'Roboto Mono'",
    },

    fontWeight: {
        bold: "800",
        medium: "600",
        regular: "400",
        light: "200",
    },

    fontSize: {
        title: "48px",
        normal: "14px",
    },

}


export type ThemeType = typeof Theme;