import type { PayloadAction } from "@reduxjs/toolkit";

// Interfaces
type TypePack = {
    id: number | string;
    name: string;
    short_description: string;
    long_description: string;
    quantity: number;
    price: number;
    price_id: string;
    image: string;
    units: number;
    flavors?: { name: string; quantity: number }[];
};

type TypePackState = {
    packs: TypePack[];
};

// Initial state

const initialState: TypePackState = {
    packs: [],
};

//Reducers

const addPacks = (state: TypePackState, action: PayloadAction<TypePack[]>) => {
    state.packs = action.payload;
};

export type { TypePack };

export { initialState, addPacks };
