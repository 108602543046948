import { TypeBeer } from "@/redux/reducers/beerReducer/beers";
import { TypeMerch } from "@/redux/reducers/merchReducer/merchFunctions";
import { useRouter } from "next/router";
import { createContext, useEffect, useState, ReactNode, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

type TypeAppContext = {
    loading: boolean;
    beers: undefined | [];
    merch: undefined | [];
    cartOpen: boolean;
    setCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppContext = createContext<TypeAppContext>({
    loading: true,
    beers: undefined,
    merch: undefined,
    cartOpen: false,
    setCartOpen: () => {},
});

export const useAppState = () => useContext(AppContext);

const AppProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { locale } = useRouter();
    const [beers, setBeers] = useState<any>();
    const [merch, setMerch] = useState<any>();
    const [cartOpen, setCartOpen] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([getBeers(), getMerchItems()])
            .then(([data1, data2]) => {
                setBeers(data1);
                setMerch(data2);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [locale]);

    const fetchAPI = async (url: string) => {
        const requestAPI = await fetch(`${url}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            next: { revalidate: 5 },
        });
        return await requestAPI.json();
    };

    const getBeers = async () => {
        const url = `/api/get-beers-data?locale=${locale}`;
        try {
            const stripeProducts = await fetch("/api/get-stripe", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });
            const stripePrices = await fetch("/api/get-stripe-prices", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });
            const products = await stripeProducts.json();
            const prices = await stripePrices.json();

            const data = await fetchAPI(url);

            const refactoredBeer: TypeBeer[] = data?.data?.map((beer: any): TypeBeer => {
                const matchingProduct = products.data.find((product: { id: string }) => product.id === beer.attributes.stripe_price_id);
                let price = "00.00";
                let quantity = 0;
                let priceId = "";
                if (matchingProduct) {
                    const defaultPriceStripe = prices.data.find((price: { id: string }) => price.id === matchingProduct.default_price);
                    let auxprice = defaultPriceStripe.unit_amount / 100;
                    price = auxprice.toFixed(2);
                    priceId = defaultPriceStripe && defaultPriceStripe.id;
                    quantity = matchingProduct.metadata.Quantity;
                }
                const {
                    name,
                    short_description,
                    hover_description,
                    long_description,
                    hover_image,
                    is_ensign_beer,
                    font_color,
                    background_color,
                    beer_type,
                    picture,
                    picture_with_background,
                    calories,
                    size,
                    ingredients,
                    is_purchasable,
                } = beer.attributes;

                const quantity_text = beer?.attributes?.quantity_text?.split(":")[1];
                const uuid = uuidv4();

                return {
                    id: uuid,
                    name,
                    short_description,
                    hover_description,
                    long_description,
                    hover_image,
                    is_ensign_beer,
                    font_color,
                    background_color,
                    beer_type,
                    quantity,
                    price,
                    price_id: priceId,
                    picture_with_background,
                    picture,
                    calories,
                    size,
                    ingredients,
                    category: "beer",
                    is_purchasable,
                    quantity_text,
                };
            });

            return refactoredBeer;
        } catch (error) {
            console.log(error);
        }
    };

    const getMerchItems = async () => {
        const url = `/api/get-merch-items?locale=${locale}`;
        try {
            const stripeProducts = await fetch("/api/get-stripe", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });
            const stripePrices = await fetch("/api/get-stripe-prices", {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });
            const products = await stripeProducts.json();
            const prices = await stripePrices.json();

            const data = await fetchAPI(url);

            const refactoredMerch: TypeMerch[] = data.data.map((merch: any): TypeMerch => {
                const matchingProduct = products.data.find((product: { id: string }) => product.id === merch.attributes.stripe_price_id);
                let price = "";
                let priceId = "";
                if (matchingProduct) {
                    const defaultPriceStripe = prices.data.find((price: { id: string }) => price.id === matchingProduct.default_price);
                    let auxprice = defaultPriceStripe.unit_amount / 100;
                    price = auxprice.toFixed(2);
                    priceId = defaultPriceStripe && defaultPriceStripe.id;
                }

                const {
                    name,
                    picture,
                    hover_description,
                    short_description,
                    long_description,
                    hover_image,
                    sizes,
                    font_color,
                    background_color,
                    picture_with_background,
                    is_purchasable,
                } = merch.attributes;

                const uuid = uuidv4();

                return {
                    id: uuid,
                    name,
                    short_description,
                    hover_description,
                    long_description,
                    hover_image,
                    font_color,
                    background_color,
                    price,
                    price_id: priceId,
                    picture_with_background,
                    picture: {
                        url: merch.attributes.picture?.data?.map((pic: { attributes: { url: string } }) => pic.attributes.url) || [],
                    },
                    sizes,
                    category: "merch",
                    is_purchasable,
                };
            });

            return refactoredMerch;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <AppContext.Provider
            value={{
                loading,
                beers,
                merch,
                cartOpen,
                setCartOpen,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;

export { AppContext };
