
import { configureStore } from '@reduxjs/toolkit'

import cart from './reducers/cartReducer/cart'
import beers from './reducers/beerReducer/beers'
import merch from './reducers/merchReducer/merch'
import packs from './reducers/packReducer/packs'

export const store = configureStore({
    reducer: {
        cart,
        beers,
        merch,
        packs
    },
});


export type RootState = ReturnType<typeof store.getState>;
