import styled from "styled-components";

export const LanguageWrapper = styled.div<{ openDropdown: boolean }>`
	width: 64px;
	border: none;
	cursor: pointer;
	color: ${(props) => props.theme.color.darkGreen};
	position: relative;
	z-index: 230;

	.select_selector {
		width: 100%;
		display: flex;
		align-tems: center;
		justify-content: space-between;
		padding: 0px 10px;
		border-radius: 10px 10px 0px 0px;
		transition: 0.5s;
		background: transparent;

		span {
			display: flex;
			font-weight: 100;
			font-size: 22px;
			color: ${(props) => props.theme.color.darkGreen};
			font-family: ${(props) => props.theme.font.title};
		}
		.arrow {
			align-self: center;
			margin-left: 12px;
			font-size: 16px;
			font-weight: 900;
			transform: ${(props) =>
				props.openDropdown ? "rotate(-90deg)" : "rotate(90deg)"};
		}
		&:hover {
		
		}
	}
	.underline {
		position: relative;
	}

	.underline::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 2px;
		background-color: ${(props) => props.theme.color.darkGreen};
		transform: scaleX(0);
		transform-origin: right;
		transition: transform 0.3s ease-in-out;
	}

	.underline:hover::after {
		transform: scaleX(1);
		transform-origin: left;
	}
	.select_options {
		display: flex;
		border-radius: 0px 0px 10px 10px;
		max-height: 200px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		background: ${(props) => props.theme.color.darkGreen};
		z-index: 230;
		color: ${(props) => props.theme.color.green};
		font-family: ${(props) => props.theme.font.title};
		width: inherit;
		position: absolute;
		.select_option {
			height: 42px;
			padding: 10px;
			align-items: center;
			display: flex;
			font-size: 22px;
			font-weight: 100;
			transition: 0.5s;
			cursor: pointer;
			font-family: ${(props) => props.theme.font.title};
			&:hover {
				background: ${(props) => props.theme.color.yellow};
			}
		}
	}
	@media screen and (max-width: 769px) {
		.select_selector span {
			font-size: 20px;
		}
		.select_selector .arrow {
			font-size: 14px;
		}
		.select_options {
			.select_option {
				font-size: 20px;
			}
		}
	}
`;
