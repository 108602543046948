import type { PayloadAction } from "@reduxjs/toolkit";

// Interfaces
type TypeMerch = {
    id: string;
    name: string;
    short_description: string;
    hover_description: string;
    long_description: string;
    hover_image: any;
    font_color: string;
    background_color: any;
    price: string;
    price_id: string;
    picture_with_background: any;
    picture: any;
    sizes: any;
    category: "merch";
    is_purchasable: boolean;
};

type TypeMerchState = {
    merch: TypeMerch[];
};

// Initial state

const initialState: TypeMerchState = {
    merch: [],
};

//Reducers

const addMerch = (state: TypeMerchState, action: PayloadAction<TypeMerch[]>) => {
    state.merch = action.payload;
};

export type { TypeMerch };

export { initialState, addMerch };
