import AppProvider, { useAppState } from "@/context/AppProvider";
import "@/styles/globals.css";
import { GlobalStyle } from "@/styles/secreto.style";
import { Theme } from "@/theme";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useEffect, useRef, useState } from "react";
import { dataPreloader } from "@/data/PrelaoderData/esp";
import { dataPreloaderCa } from "@/data/PrelaoderData/ca";
import { Preloader } from "@/components/Preloader/Preloader";
import { dataPreloaderEn } from "@/data/PrelaoderData/en";
import { dataPreloaderFr } from "@/data/PrelaoderData/fr";
import Head from "next/head";

const metaData = [
    {
        path: "/",
        data: {
            es: "El Secreto Brewing | Home",
            en: "El Secreto Brewing | Home",
            fr: "El Secreto Brewing | Home",
            ca: "El Secreto Brewing | Home",
        },
    },
    {
        path: "/shop",
        data: {
            es: "ESB | Shop",
            en: "ESB | Shop",
            fr: "ESB | Shop",
            ca: "ESB | Shop",
        },
    },
    {
        path: "/commerce",
        data: {
            es: "ESB | Negocios",
            en: "ESB | Commerce",
            fr: "ESB | Entreprise",
            ca: "ESB | Negocis",
        },
    },
    {
        path: "/about",
        data: {
            es: "ESB | Sobre nosotros",
            en: "ESB | About",
            fr: "ESB | A propos de nous",
            ca: "ESB | Sobre nosaltres",
        },
    },
];

export default function App({ Component, pageProps }: AppProps) {
    const Router = useRouter();
    const { cartOpen } = useAppState();
    const path = Router.asPath;
    const { locale } = Router;
    const [displayPreloader, setDisplayPreloader] = useState<boolean>(false);
    const title = metaData.find((data) => data.path === path)?.data[locale as keyof typeof locale];
    let item: string = "";

    const capitalice = (title: string) => {
        title = title.replace(/-/g, " ");
        return title.charAt(0).toUpperCase() + title.slice(1);
    };

    if (Router.query && Router.query.item) {
        if (Array.isArray(Router.query.item)) {
            item = capitalice(Router.query.item[0]);
        } else {
            item = capitalice(Router.query.item);
        }
    }

    function saveLegalAge(key: string, value: string, hours: number) {
        const now = new Date().getTime();
        const expiration = now + hours * 60 * 60 * 1000;
        const valueWithExpirationDate = {
            value,
            expiration,
        };
        localStorage.setItem(key, JSON.stringify(valueWithExpirationDate));
    }

    function getLegalAgeExpiration(key: string) {
        const cookie = localStorage.getItem(key);

        if (cookie) {
            const legalAgeExpiration = JSON.parse(cookie);

            if (legalAgeExpiration) {
                const today = new Date().getTime();
                if (today < legalAgeExpiration.expiration) {
                    return legalAgeExpiration.value;
                } else {
                    localStorage.removeItem(key);
                }
            }
        } else {
            return null;
        }
    }

    useEffect(() => {
        const hasLegalAge = localStorage.getItem("legal age");
        if (hasLegalAge) {
            const isLegal = getLegalAgeExpiration("legal age");
            if (isLegal) {
                setDisplayPreloader(false);
            } else {
                setDisplayPreloader(true);
            }
        } else {
            setDisplayPreloader(true);
        }
    }, []);

    const handleClosePreloader = () => {
        window.scrollTo(0, 0);
        saveLegalAge("legal age", "true", 24);
        setDisplayPreloader(false);
    };

    return (
        <>
            <Head>
                <title>{title ? title : path.startsWith("/shop/") ? `ESB | ${item}` : "El secreto brewing"}</title>
            </Head>
            <Provider store={store}>
                <AppProvider>
                    <ThemeProvider theme={Theme}>
                        <GlobalStyle cartOpen={cartOpen} />
                        <Component key={path} {...pageProps} />
                        {displayPreloader && dataPreloader && (
                            <Preloader
                                handleClose={handleClosePreloader}
                                data={
                                    locale === "es"
                                        ? dataPreloader
                                        : locale === "en"
                                        ? dataPreloaderEn
                                        : locale === "fr"
                                        ? dataPreloaderFr
                                        : dataPreloaderCa
                                }
                            />
                        )}
                    </ThemeProvider>
                </AppProvider>
            </Provider>
        </>
    );
}
