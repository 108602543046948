import type { PayloadAction } from "@reduxjs/toolkit";

// Interfaces
// type TypeProduct = {
//     category?: string
//     units?: number
//     id: string;
// 	name: string;
// 	short_description: string;
// 	hover_description: string;
// 	long_description: string;
// 	is_ensign_beer?: boolean;
// 	font_color?: string;
// 	background_color?: string;
// 	beer_type?: string;
// 	quantity?: number;
// 	price?: any;
// 	price_id?: string;
// 	picture_with_background?: any;
//     picture?: any;
// 	calories?: string;
// 	size?: string;
// 	ingredients?: string;
//     flavors?: { name: string; quantity: number }[]
// }

type TypeProduct = any;

type TypeCartState = {
    products: TypeProduct[];
    price: number;
};

// Initial state

const initialState: TypeCartState = {
    products: [],
    price: 0,
};

// Reducers

const addProductFunction = (state: TypeCartState, action: PayloadAction<TypeProduct>) => {
    const { id, name, price, price_id, quantity, size = "", picture, category, picture_with_background, flavors } = action.payload;

    const product = state.products.find((product: TypeProduct) => product.id === id && product.size === size);
    if (product) product.quantity += quantity;
    else
        state.products.push({
            id,
            name,
            price,
            price_id,
            quantity,
            picture,
            picture_with_background,
            size,
            category,
            flavors,
        });

    let totalPrice = 0;

    for (let i = 0; i < state?.products?.length; ++i) {
        totalPrice += state?.products[i]?.price * state.products[i]?.quantity;
    }

    state.price = totalPrice;
};

const removeProductFunction = (state: TypeCartState, action: PayloadAction<{ id: number | string; size?: string }>) => {
    state.products = state.products.filter(
        (product: TypeProduct) => product.id !== action.payload.id || (product.id === action.payload.id && product.size !== action.payload.size)
    );

    let totalPrice = 0;

    for (let i = 0; i < state.products.length; ++i) {
        totalPrice += state.products[i].price * state.products[i].quantity;
    }

    state.price = totalPrice;
};

export type { TypeProduct };

export { initialState, addProductFunction, removeProductFunction };
