import type { PayloadAction } from "@reduxjs/toolkit";

type TypeBeer = {
    id: string;
    name: string;
    short_description: string;
    hover_description: string;
    hover_image: any;
    long_description: string;
    is_ensign_beer: boolean;
    font_color: string;
    background_color: string;
    beer_type: string;
    quantity: number;
    price: string;
    price_id?: string;
    picture_with_background?: any;
    picture?: any;
    calories: string;
    size: string;
    ingredients: string;
    category: string;
    is_purchasable: boolean;
    quantity_text: string;
};

type TypeBeersState = {
    beers: TypeBeer[];
};

const initialState: TypeBeersState = {
    beers: [],
};

const addBeers = (state: TypeBeersState, action: PayloadAction<TypeBeer[]>) => {
    state.beers = action.payload;
};

export type { TypeBeer };

export { initialState, addBeers };
