
import { createSlice } from "@reduxjs/toolkit";

import { TypeProduct } from "./cartFunctions";

import { 
    initialState, 
    addProductFunction, 
    removeProductFunction 
} from "./cartFunctions";


// Slice

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addProduct: addProductFunction,
        removeProduct: removeProductFunction
    },
});

export const { addProduct, removeProduct } = cartSlice.actions;

export type { TypeProduct };

export default cartSlice.reducer;



