
import { createSlice } from "@reduxjs/toolkit";

import { TypePack } from "./packFunctions";

import { 
    initialState,
    addPacks
} from "./packFunctions";

// Slice

export const packsSlice = createSlice({
    name: 'packs',
    initialState,
    reducers: {
        addArrayPacks: addPacks
    },
});

export const { addArrayPacks } = packsSlice.actions;

export type { TypePack };

export default packsSlice.reducer;
