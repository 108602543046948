
import { createSlice } from "@reduxjs/toolkit";

import { TypeMerch } from "./merchFunctions";

import { 
    initialState,
    addMerch
} from "./merchFunctions";

// Slice

export const merchSlice = createSlice({
    name: 'merch',
    initialState,
    reducers: {
        addArrayMerch: addMerch
    },
});

export const { addArrayMerch } = merchSlice.actions;

export type { TypeMerch };

export default merchSlice.reducer;
