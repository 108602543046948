import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { LanguageWrapper } from "./Language.style";

export const Language = ({ className }: { className: string }) => {
    const router = useRouter();
    const [openDropdown, setOpenDropdown] = useState(false);
    const { locale } = router;
    const { pathname, asPath, query } = router;
    const dropdownRef = useRef<HTMLDivElement>(null);
    const navbarOptions = [
        { name: "ES", value: "es" },
        { name: "CA", value: "ca" },
        { name: "EN", value: "en" },
        { name: "FR", value: "fr" },
    ];

    const handleOutsideClicks = (e: MouseEvent) => {
        if (dropdownRef.current && dropdownRef?.current?.contains(e.target as Node)) {
            return;
        }

        setOpenDropdown(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClicks);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClicks);
        };
    }, [openDropdown]);

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown);
    };

    const handleSelect = (e: any) => {
        handleRefresh(e.target.title);
        setOpenDropdown(false);
    };

    const handleRefresh = (link: string) => {
        router.push({ pathname, query }, asPath, { locale: link });
    };

    return (
        <LanguageWrapper openDropdown={openDropdown} className={className}>
            <div ref={dropdownRef} className="select_selector underline" onClick={handleDropdown}>
                <span>
                    {navbarOptions?.find((option) => option?.value === locale)?.name} <span className="arrow">❯</span>
                </span>
            </div>
            {openDropdown && (
                <div className="select_options" ref={dropdownRef}>
                    {navbarOptions?.map((option) => {
                        return (
                            <label key={option?.value} title={option?.value} className="select_option" onClick={(e) => handleSelect(e)}>
                                {option?.name}
                            </label>
                        );
                    })}
                </div>
            )}
        </LanguageWrapper>
    );
};
