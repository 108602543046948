import React, { useRef, useState, useEffect } from "react";
import { PreloaderWrapper } from "./Preloader.style";
import Image from "next/image";
import { Language } from "../Language/Language";

export const Preloader = ({ handleClose, data }: { handleClose: Function; data: any }) => {
    const preloaderRef = useRef(null);
    const [titleDisplayed, setTitleDisplayed] = useState<string>("legal");

    const handleAccess = () => {
        titleDisplayed === "legal" && setTitleDisplayed("welcome");
    };

    useEffect(() => {
        titleDisplayed === "welcome" &&
            setTimeout(() => {
                setTitleDisplayed("logo");
            }, 1400);
        titleDisplayed === "logo" &&
            setTimeout(() => {
                handleClose();
            }, 2500);
    }, [titleDisplayed]);

    return (
        <PreloaderWrapper ref={preloaderRef}>
            <div className="header">
                <Language className="language" />
            </div>
            {titleDisplayed === "legal" && data?.title_1 && (
                <div className="legal_age">
                    <p>{data?.title_1}</p>
                    <button onClick={handleAccess}>{data?.button}</button>
                </div>
            )}
            {titleDisplayed === "welcome" && (
                <div className="text">
                    <p>{data?.title_2}</p>
                </div>
            )}
            {titleDisplayed === "logo" && (
                <div className="text_logo">
                    <div className="logo">
                        <Image src={data?.logo} fill alt="El Secreto" />
                    </div>
                </div>
            )}
        </PreloaderWrapper>
    );
};
