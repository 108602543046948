import styled from "styled-components";

export const PreloaderWrapper = styled.section<{ ref: any }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 210;
    top: 0;
    left: 0;
    background: black;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.color.green};
    justify-content: center;
    align-items: center;
    .header {
        width: 100%;
        height: 15vh;
        z-index: 220;
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding: 0px 5%;
        align-items: center;
    }
    .language {
        border: none;
        cursor: pointer;
        position: absolute;
        right: 150px;
        margin-right: 5%;
        @media screen and (max-width: 769px) {
            display: none;
        }
    }
    @keyframes fadeInAndOut {
        0% {
            opacity: 0%;
        }
        50% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
    @keyframes scaleInAndOut {
        0% {
            transform: scale(0.6);
        }

        100% {
            transform: scale(1);
        }
    }
    .legal_age {
        width: 80%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 220;
        position: relative;
        p {
            color: ${(props) => props.theme.color.darkGreen};
            font-family: ${(props) => props.theme.font.title};
            font-size: 50px;
            text-transform: uppercase;
            width: 700px;
            text-align: center;
            margin-top: -5%;
        }
        button {
            box-shadow: 0px 5px 10px 0px rgb(0, 0, 0, 0.5);
            border: none;
            font-family: ${(props) => props.theme.font.text};
            text-transform: uppercase;
            width: 200px;
            border-radius: 20px;
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: ${(props) => props.theme.color.lightGreen};
            background: ${(props) => props.theme.color.darkGreen};
            transition: 0.5s ease;
            &:hover {
                background: ${(props) => props.theme.color.lightGreen};
                color: ${(props) => props.theme.color.black};
            }
        }
    }

    .text {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 220;
        position: relative;
        animation: fadeInAndOut 2s ease;
        p {
            color: ${(props) => props.theme.color.darkGreen};
            font-family: ${(props) => props.theme.font.title};
            font-size: 170px;
            text-transform: uppercase;
            text-align: center;
            height: 200px;
            position: absolute;
            top: 4%;
            animation: scaleInAndOut 3s ease;
        }
    }
    .text_logo {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 220;
        position: relative;
        animation: fadeInAndOut 3s ease;
        .logo {
            width: 700px;
            position: absolute;
            height: 200px;
            top: 30%;
            animation: scaleInAndOut 3s ease;
        }
    }

    @media screen and (max-width: 850px) {
        .legal_age {
            p {
                font-size: 25px;
                width: 90%;
                margin-top: -10%;
            }
        }
        .text {
            margin-top: 30%;
            p {
                width: 80%;
                font-size: 50px;
                text-align: center;
            }
        }
        .text_logo {
            .logo {
                width: 80%;
                margin-top: -10%;
            }
        }
    }
`;
